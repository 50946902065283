<template>
    <div>
        <!-- <el-carousel :interval="4000" type="card" height="500px">
            <el-carousel-item v-for="item in imageData" :key="item">
                <el-image :src="item" >
                    <div class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                </el-image>
            </el-carousel-item>
        </el-carousel> -->

        <el-carousel indicator-position="outside"  style="height:600px;">
            <el-carousel-item v-for="item in imageData" :key="item" style="height:600px;">
            <!-- :preview-src-list="imageData" -->
                <div  style="display:flex;justify-content:center;">
                <el-image :src="item"  fit="contain" style="width: 600px; height: 500px">
                    <div class="image-slot" >
                        加载中<span class="dot">...</span>
                    </div>
                </el-image>
                </div>
            </el-carousel-item>
        </el-carousel>

    </div>
    <div class="button-footer">                      
        <span >
            <el-button @click="close()">关闭</el-button>
        </span>                    
    </div>
</template>

<script>
export default {
    props:['imageData'],
    data() {
      return {
        // fit: 'contain',
      }
    },
    methods:{
        close(){
            // this.$parent.closeDetail();
            this.$emit('close');
        },
    }
}
</script>

<style>

</style>