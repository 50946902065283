<template>  
      <!-- <div class="echart" :id="objName" :style="{float:'left',width: '100%', height: '600px'}"></div>    -->
      <div class="echart" :id="objName" :style="style"></div>   
</template>

<script>

import echarts from  "echarts";

export default {
    props: {
          objName: {
              type: String,
              default: "echart-line"
          },
          style:{
              type:Object,
              default:{float:'left',width: '90%', height: '400px'}
          },
        //   seriesType:{
        //       type:String,
        //       default:'pie'
        //   }
      },
      methods:{

      initChart(title,seriesName,data) {       
          let getchart = echarts.init(document.getElementById(this.objName));
        //   var ecConfig = require('echarts/config');
        //   getchart.on(ecConfig.EVENT.CLICK, eConsole);
        // console.log('jinru chart',data,this.objName)

          var  option = {
              title: {
                    text: title,
                    // subtext: 'Fake Data',
                    left: 'center',
                    textStyle:{
                        fontSize:13
                    }
                },
                color:['#F89616', '#5EA690', '#93C73E','#E2338B','#F48781','#FF9393'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                 grid: {
                    left: '10%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    
                },
                yAxis: {
                    type: 'category',
                    data: data.xAxisData,
                    axisLabel: {
                        // interval: 0,
                        rotate: -45,
                        //倾斜度 -90 至 90 默认为0  
                        // margin: 2,
                        // textStyle: {
                        //     fontWeight: "bolder",
                        //     color: "#000000"
                        // }
                    },
                },
                series: [
                    {
                
                    type: 'bar',
                    data: data.seriesData
                    },
                    
                ]
            };

          getchart.setOption(option);

          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
              getchart.resize();
          });
        
        const _this = this;

        getchart.on('click',function(e){
            // console.log(e);
            _this.$emit('onchange',e.name);
            
        })


          
      },

    }

}
</script>