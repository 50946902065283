<template>  
      <!-- <div class="echart" :id="objName" :style="{float:'left',width: '100%', height: '600px'}"></div>    -->
      <div class="echart" :id="objName" :style="style"></div>   
</template>

<script>

import echarts from  "echarts";

export default {
    props: {
          objName: {
              type: String,
              default: "echart-line"
          },
          style:{
              type:Object,
              default:{float:'left',width: '100%', height: '400px'}
          },
        //   seriesType:{
        //       type:String,
        //       default:'pie'
        //   }
      },
      methods:{

      initChart(title,seriesName,data,seriesType="pie") {       
          let getchart = echarts.init(document.getElementById(this.objName));
        //   var ecConfig = require('echarts/config');
        //   getchart.on(ecConfig.EVENT.CLICK, eConsole);
        console.log('jinru chart',data,this.objName)

          var  option = {
              title: {
                    text: title,
                    // subtext: 'Fake Data',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                    // trigger: 'axis',
                    //     axisPointer: {
                    //         label: {
                    //             show: true,
                    //             fontSize: 15
                    //         }
                    //     },
                    // formatter: "{b}月份：{c}%",
                    // formatter(params) {
                    //     console.log('params',params)
                    //     for (let x in params) {
                    //     return (
                    //         params[x].label +
                    //         "月份：" +
                    //         params[x].value
                    //     );
                    //     }
                    // },
                },
                // legend: {
                //     top: '5%',
                //     left: 'center'
                // },
                color:['#F89616', '#5EA690', '#93C73E','#E2338B','#F48781','#FF9393'],
                series: [
                    {
                    name: seriesName,
                    type: 'pie',
                    radius: ['40%', '70%'],
                    
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data
                    }
                ]
            //     series: [
            //     {
            //     name: 'Access From',
            //     type: 'pie',
            //     radius: ['40%', '70%'],
                
            //     label: {
            //         show: false,
            //         position: 'center'
            //     },

            //     data: [
            //         { value: 1048, name: 'Search Engine' },
            //         { value: 735, name: 'Direct' },
            //         { value: 580, name: 'Email' },
            //         { value: 484, name: 'Union Ads' },
            //         { value: 300, name: 'Video Ads' }
            //     ]
            //     }
            // ]
            };
 
          getchart.setOption(option);

          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
              getchart.resize();
          });
        
        const _this = this;

        getchart.on('click',function(e){
            // console.log(e);
            _this.$emit('onchange',e.name);
            
        })
        



          
      },

    }

}
</script>