<template>
  <div class="main">
      <div class="button-footer">                      
            <span >
                
                <el-button @click="close()">关闭</el-button>
            </span>                    
       </div>
        <el-row>
            <el-col :span="11">
                <el-tabs v-model="activeBase" @tab-click="handleClick">                    
                    <el-tab-pane v-if="stationInfo.infoBase.czwgPic" label="车站外观" name="firstBase">
                        <el-image :src="stationInfo.infoBase.czwgPic">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </el-tab-pane>

                    <el-tab-pane v-if="stationInfo.infoBase.znfbPic" label="站内分布" name="secondBase">
                        <el-image :src="stationInfo.infoBase.znfbPic">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </el-tab-pane>
                    <!-- 去掉 v-if="stationInfo.platformImgs" 否则轮播图重叠 -->
                    <el-tab-pane  label="站层平面图" name="thirdBase">
                        <el-carousel class="carousel" :interval="5000" arrow="always">
                            <el-carousel-item  v-for="item in stationInfo.platformImgs" :key="item">     
                                <div>          
                                <el-image  style="width:600px;height:400px;" :src="item"  fit="fill">
                                    <div class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                </el-image>
                                </div>    
                            </el-carousel-item>
                        </el-carousel>
                    </el-tab-pane>

                    <el-tab-pane v-if="stationInfo.infoBase.yxxlPic" label="运行线路图" name="fourthBase">
                        <el-image :src="stationInfo.infoBase.yxxlPic">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </el-tab-pane>

                    <el-tab-pane label="随手拍"  name="随手拍"  lazy> 
                        <el-carousel class="carousel" :interval="5000" arrow="always">
                            <el-carousel-item v-for="item in stationInfo.suishoupai" :key="item">                        
                                <el-image :src="item.imgs"  fit="fill">
                                    <div class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                </el-image>
                            </el-carousel-item>
                        </el-carousel>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="12" >
                <el-tabs v-model="activeMedia" @tab-click="handleClick">
                    <el-tab-pane label="车站信息" name="firstMedia">
                        <div style="display:flex;flex-direction:column;justify-content:space-between ">
                            <div>
                                <div style="font-size: 1.64rem;">{{stationInfo.infoBase.station}}</div>
                                <div style="margin-top:10px;">&#12288;&#12288;{{stationInfo.infoBase.describe1}}</div>
                                <div style="margin-top:10px;">
                                    <el-row v-if="stationInfo.infoBase.describe2">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;车站等级:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe2}}</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe3">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;车站面积:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe3}}</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe4">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;站台规模:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe4}}</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe5">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;地铁接驳:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe5}}</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe6">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;主要线路:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe6}}</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe7">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;所属路局:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe7}}</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe8">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;年发送旅客量:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe8}}万人</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe9">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;节假日日均客流量:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe9}}万人</span></el-col>
                                    </el-row>

                                    <el-row v-if="stationInfo.infoBase.describe10">
                                        <el-col :span="8"><span style="font-weight: bolder;">&#12288;&#12288;平日日均客流量:</span></el-col>
                                        <el-col :span="16"><span>{{stationInfo.infoBase.describe10}}万人</span></el-col>
                                    </el-row>

                                </div>
                                
                            </div>

                            <div style="margin-top:20px;">
                                <el-tabs v-model="activeMediaCate" @tab-click="handleClick">
                                    <el-tab-pane label="辐射城市" name="firstMediaCate">

                                        <el-tabs>
                                            <el-tab-pane v-for="item in stationInfo.cityLevels" :key="item" :label="item.levelName">
                                                <span style="margin-left:5px;" v-for="subItem in item.city" :key="subItem">{{subItem}}</span>
                                            </el-tab-pane>
                                        </el-tabs>                               
                                        
                                    </el-tab-pane>
                                    <el-tab-pane label="运行线路" name="secondMediaCate">
                                        <span style="margin-left:5px;" v-for="item in stationInfo.stationLines" :key="item">{{item}}</span>
                                    </el-tab-pane>
                                    <el-tab-pane label="周边景点" name="thirdMediaCate">
                                        <span style="margin-left:5px;" v-for="item in stationInfo.scenics" :key="item">·{{item}}</span>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane label="站点资源" name="secondMedia">
                        <el-tabs  v-model="activeMediaDevice">
                            <el-tab-pane  v-if="stationInfo.mediasLed && stationInfo.mediasLed.length>0" label="LED" name="secondMediaLed">
                                <el-table
                                    :data="stationInfo.mediasLed"
                                    border
                                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                                    style="width: 100%; margin-top: 10px; font-size: x-small"
                                    stripe 
                                >
                                    <el-table-column prop="name" align="center" label="套餐"></el-table-column>
                                    <el-table-column prop="address" align="center" label="发布位置">
                                        <template v-slot="scope">
                                            <i class="el-icon-s-management" :style="scope.row.imgs.length>0?'cursor: pointer;':''" @click="openMedia(scope.row.imgs)"><span>{{scope.row.address}}</span></i>
                                            
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column prop="deviceType" align="center" label="套餐名称"></el-table-column> -->
                                    <el-table-column align="center" label="屏幕数量">
                                        <template v-slot="scope">
                                            <el-tooltip class="item" effect="dark" placement="left-start" >
                                                <template v-slot:content> 
                                                    <div >
                                                        <ul>
                                                            <li v-for="item in scope.row.mediaCodes" :key="item">{{item}}</li>
                                                        </ul>
                                                    </div>
                                                </template>
                                                <div class="grid-cont-right">
                                                    <span>{{scope.row.totalCount}}</span>
                                                </div>
                                            </el-tooltip>                                            
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="adSize" align="center" label="尺寸（米）"></el-table-column>
                                </el-table>
                            </el-tab-pane>

                            <el-tab-pane  v-if="stationInfo.mediasDx && stationInfo.mediasDx.length>0" label="灯箱" name="secondMediaDx">
                                <el-table
                                    :data="stationInfo.mediasDx"
                                    border
                                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                                    style="width: 100%; margin-top: 10px; font-size: x-small"
                                    stripe 
                                >
                                    <el-table-column prop="name" align="center" label="套餐"></el-table-column>
                                    <el-table-column prop="address" align="center" label="发布位置">
                                        <template v-slot="scope">
                                            <i class="el-icon-s-management" :style="scope.row.imgs.length>0?'cursor: pointer;':''" @click="openMedia(scope.row.imgs)"><span>{{scope.row.address}}</span></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="deviceType" align="center" label="形式"></el-table-column>
                                    <el-table-column align="center" label="屏幕数量">
                                        <template v-slot="scope">
                                            <el-tooltip class="item" effect="dark" placement="left-start" >
                                                <template v-slot:content> 
                                                    <div >
                                                        <ul>
                                                            <li v-for="item in scope.row.mediaCodes" :key="item">{{item}}</li>
                                                        </ul>
                                                    </div>
                                                </template>
                                                <div class="grid-cont-right">
                                                    <span>{{scope.row.totalCount}}</span>
                                                </div>
                                            </el-tooltip>                                            
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="adSize" align="center" label="尺寸（米）"></el-table-column>
                                </el-table>
                            </el-tab-pane>

                            <el-tab-pane  v-if="stationInfo.mediasDx && stationInfo.mediasDx.length>0" label="列车" name="secondMediaLc">
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>

                    <el-tab-pane label="刊例价格" name="thirdMedia">
                        <el-tabs  v-model="activeMediaPrice">
                            <el-tab-pane  v-if="stationInfo.mediaLedPrices && stationInfo.mediaLedPrices.length>0" label="LED" name="thirdMediaLed">
                                <el-table
                                    :data="stationInfo.mediaLedPrices"
                                    border
                                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                                    style="width: 100%; margin-top: 10px; font-size: x-small"
                                    stripe 
                                >
                                    <el-table-column prop="name" align="center" label="套餐"></el-table-column>
                                    <el-table-column prop="cost10" align="center" label="10秒单价"></el-table-column>
                                    <el-table-column prop="cost15" align="center" label="15秒单价"></el-table-column>
                                    <el-table-column align="center" label="点位">
                                        <template v-slot="scope">
                                            <p v-if="scope.row.address" v-html="scope.row.address.replace(',','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p>     
                                            <p v-else></p>                                     
                                        </template>
                                    </el-table-column>                                    
                                </el-table>
                            </el-tab-pane>

                            <el-tab-pane  v-if="stationInfo.mediaDxPrices && stationInfo.mediaDxPrices.length>0" label="灯箱" name="thirdMediaDx">
                                <el-table
                                    :data="stationInfo.mediaDxPrices"
                                    border
                                    :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                                    style="width: 100%; margin-top: 10px; font-size: x-small"
                                    stripe 
                                >
                                    <el-table-column prop="name" align="center" label="套餐"></el-table-column>
                                    <el-table-column prop="typeName" align="center" label="形式"></el-table-column>
                                    <el-table-column prop="cost" align="center" label="单价"></el-table-column>
                                    <el-table-column prop="address" align="center" label="发布位置"></el-table-column>
                                    
                                </el-table>
                            </el-tab-pane>

                            <el-tab-pane  v-if="stationInfo.mediaDxPrices && stationInfo.mediaDxPrices.length>0" label="列车" name="thirdMediaLc">
                            </el-tab-pane>
                        </el-tabs>

                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>

        <el-row style="margin-top:30px;">
            <el-col :span="24">
                <div style>
                    <line-chart ref="monthChart" :obj-name="'monthChart'" @onchange="onchange"/>   
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <ring-chart ref="genderChart" :obj-name="'genderChart'" @onchange="onchange"/>   
            </el-col>
            <el-col :span="8">
                <vertical-bar-chart ref="targetChart" :obj-name="'targetChart'" @onchange="onchange"/>   
            </el-col>
            <el-col :span="8">
                <vertical-bar-chart ref="ageChart" :obj-name="'ageChart'" @onchange="onchange"/>   
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <vertical-bar-chart ref="jobChart" :obj-name="'jobChart'" @onchange="onchange"/>   
            </el-col>
            <el-col :span="8">
                <vertical-bar-chart ref="bussTrafficChart" :obj-name="'bussTrafficChart'" @onchange="onchange"/>   
            </el-col>
            <el-col :span="8">
                <vertical-bar-chart ref="tourTrafficChart" :obj-name="'tourTrafficChart'" @onchange="onchange"/>   
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <vertical-bar-chart ref="eduChart" :obj-name="'eduChart'" @onchange="onchange"/>   
            </el-col>
            <el-col :span="8">
                <vertical-bar-chart ref="incomeChart" :obj-name="'incomeChart'" @onchange="onchange"/>   
            </el-col>
            <el-col :span="8">
                <vertical-bar-chart ref="outcomeChart" :obj-name="'outcomeChart'" @onchange="onchange"/>   
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <vertical-bar-chart ref="spendChart" :obj-name="'spendChart'" @onchange="onchange"/>   
            </el-col>
            <el-col :span="8">
                <vertical-bar-chart ref="timeChart" :obj-name="'timeChart'" @onchange="onchange"/>   
            </el-col>
            <!-- <el-col :span="8">
                <vertical-bar-chart ref="ageChart" :obj-name="'ageChart'" @onchange="onchange"/>   
            </el-col> -->
        </el-row>


         <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
            <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
        </el-dialog>
         
     </div>
</template>

<script>

import { mapGetters } from "vuex";
import Schart from 'vue-schart';
import MediaDetail from '@/views/Business/Smart/MediaDetail';
import LineChart from "@/components/LineChart";
// import PieChart from "@/components/PieChart";
import RingChart from "@/components/RingChart";
import VerticalBarChart from "@/components/VerticalBarChart";

export default {
    props:['station'],
    components: {
        Schart,MediaDetail,LineChart,RingChart,VerticalBarChart//,PieChart
    },
    data() {
        return {
            stationInfo:{
                infoBase:{},
                platformImgs:[],
                cityLevels:[],
                stationLines:[],
                scenics:[],
                mediasLed:[],
                mediasDx:[],
                mediaLedPrices:[],
                mediaDxPrices:[],
                personCounts:[],
                genders:[],
                targets:[],
                ages:[],
                jobs:[],
                bussTraffics:[],
                tourTraffics:[],
                edus:[],
                incomes:[],
                outcomes:[],
                spends:[],
                times:[],
            },
            viewMediaVisible:false,
            imageList:[],
            activeBase: 'firstBase',
            activeMedia: 'firstMedia',
            activeMediaCate:'firstMediaCate',
            activeMediaDevice:'secondMediaLed',
            activeMediaPrice:'thirdMediaLed',
            // suishoupai:['https://hfweb.fusewise-info.com/AdEffectService/UploadBCSFiles/suishoupai/1.jpg'
            //     ,'https://hfweb.fusewise-info.com/AdEffectService/UploadBCSFiles/suishoupai/2.jpg'
            //     ,'https://hfweb.fusewise-info.com/AdEffectService/UploadBCSFiles/suishoupai/3.jpg'
            //     ]
        };
    },

    computed: {
        ...mapGetters(["GetStationInfo"])
    },

    async mounted() {
        // console.log(this.station)
        await this.$store.dispatch('operate/GetStationInfo',{station:this.station}).then(()=>{
            this.stationInfo=this.GetStationInfo;
            
            this.drawAllEchart();
        })
               
    },

    methods:{
        handleDateChange(){
            this.drawEchart();
        },

        close(){
            this.$emit('close');
        },

        open() {
            // console.log("do open");
            this.$nextTick(() => {
                //  执行echarts方法
            });
        },

        openMedia(items){
            // console.log('aaa',items)
            if(items.length>0){
                this.imageList=items;
                this.viewMediaVisible=true;
            }
        },

        onchange(curLabel){

        },

        async drawAllEchart(){
            console.log(this.stationInfo.personCounts)
            this.$refs.monthChart.initChart('客流量指标(单位：万人)','客流量',this.stationInfo.personCounts);
            var gender=[];
            this.stationInfo.genders.forEach(ele => {
                gender.push({value:ele.value,name:ele.label});
            });
            this.$refs.genderChart.initChart('人群结构(性别)','占比',gender);//this.stationInfo.genders);
            this.$refs.targetChart.initChart('人群结构(出行目的)','占比',this.stationInfo.targets,"bar");
            this.$refs.ageChart.initChart('人群结构(年龄)','占比',this.stationInfo.ages,"bar");

            this.$refs.jobChart.initChart('人群结构(职业)','占比',this.stationInfo.jobs);
            this.$refs.bussTrafficChart.initChart('人群结构(商旅人士出行交通工具选择偏好)','占比',this.stationInfo.bussTraffics,"bar");
            this.$refs.tourTrafficChart.initChart('人群结构(旅游人士出行交通工具选择偏好)','占比',this.stationInfo.tourTraffics,"bar");

            this.$refs.eduChart.initChart('人群结构(教育程度)','占比',this.stationInfo.edus);
            this.$refs.incomeChart.initChart('人群结构(家庭月收入)','占比',this.stationInfo.incomes,"bar");
            this.$refs.outcomeChart.initChart('人群结构(家庭月支出)','占比',this.stationInfo.outcomes,"bar");

            this.$refs.spendChart.initChart('人群结构(旅游人士出行花费)','占比',this.stationInfo.spends);
            this.$refs.timeChart.initChart('人群结构(旅游人士出行时间选择偏好)','占比',this.stationInfo.times,"bar");
            
        },

  
    }

}
</script>

<style>
  *{
              margin: 0;
              padding: 0;
         }
         .main{
             width: 96%;
             /* height: 800px; */
             position: absolute;
         }
         .quarter-div{
             width: 49%;
             /* height: 48%; */
             float: left;
         }

        .el-carousel__container{
            height:480px;
            /* height: 100% !important; */
         }
</style>