<template>  
      <!-- <div class="echart" :id="objName" :style="{float:'left',width: '100%', height: '600px'}"></div>    -->
      <div class="echart" :id="objName" :style="style"></div>   
</template>

<script>

import echarts from  "echarts";

export default {
    props: {
          objName: {
              type: String,
              default: "echart-line"
          },
          style:{
              type:Object,
              default:{float:'left',width: '100%', height: '300px'}
          },
        //   seriesType:{
        //       type:String,
        //       default:'pie'
        //   }
      },
      methods:{

      initChart(title,seriesName,data) {       
          let getchart = echarts.init(document.getElementById(this.objName));
        // console.log('aa',data)
        //   var ecConfig = require('echarts/config');
        //   getchart.on(ecConfig.EVENT.CLICK, eConsole);
        // console.log('jinru chart',data,this.objName)

          var  option = {
               title: {
                    text: title,
                    // subtext: 'Fake Data',
                    left: 'center'
                },
                color:['#F89616', '#5EA690', '#93C73E','#E2338B','#F48781','#FF9393'],
                tooltip: {
                    trigger: 'axis'
                },
                // legend: {
                //     data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
                // },

                toolbox: {
                    feature: {
                    saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    data: data.xAxisData
                },
                yAxis: {
                    type: 'value',
                    formatter: '{value} 万元'
                },
                series: [
                    {
                    name: seriesName,
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    data: data.seriesData
                    },
                    
                ]



            };

          getchart.setOption(option);

          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
              getchart.resize();
          });
        
        const _this = this;

        getchart.on('click',function(e){
            // console.log(e);
            _this.$emit('onchange',e.name);
            
        })
        //   

        //   this.chartEvents = {
        //         click: function(params) {
        //             console.log('params',_this.quickType,params.name+'^'+_this.filter)
        //             _this.$router.push({name: 'DataListQuick',params:{quickType: _this.quickType,quickProvince:'',quickCommon:params.name+'^'+_this.filter}});     
        //         }
        //     }

        // function eConsole(param) {    
        //     if (typeof param.seriesIndex == 'undefined') {    
        //         return;    
        //     }    
        //     if (param.type == 'click') {    
        //         alert(param.data+1);    
        //     }    
        // }



          
      },

    }

}
</script>